import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuard } from './Services/auth_guard/auth_guard.service';
import { environment } from 'src/environments/environment';


const routes: Routes = [
  
  // Home 1 Path
  { 
    path: "", 
    loadChildren: () => import("./Lazy-loaded-Modules/Home-Modules/home/home.module").then( m => m.HomeModule ), 
    data: {name: 'default', roles: ['all']}, 
    canActivate: [AuthGuard] 
  },
  { 
    path: "login", 
    loadChildren: () => import("./Lazy-loaded-Modules/Home-Modules/home/login/login.module").then( m => m.LoginModule ), 
    data: {name: 'default', roles: ['all']}, 
    canActivate: [AuthGuard] 
  },
  
];



@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false })],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Injector],
      multi: true
    }
  ],
  exports: [RouterModule]
 })
export class AppRoutingModule { }


export function initApp(injector: Injector) {
  return () => {
    return new Promise<void>((resolve) => {
      const orgId = environment.orgId;
      const filteredRoutes = routes.filter((r:any) => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);
      resolve();
    });
  };
}
